import React, { ReactNode, memo, useMemo } from 'react';

// import { getAvailableIndustriesList } from '_constants/onboardingConstants';

import {
  ProfileFieldErrorType,
  ProfileFieldLocationType,
} from '_types/profile.interface';

import DropDownList from 'app/components/DropDownList/DropDownList';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import useGoToFirstErrorId from 'utils/contexts/ProfileFieldIdsContext/useGoToFirstErrorId';
import useIndustryFieldConfiguration from 'utils/hooks/profile-field-configuration/useIndustryFieldConfiguration';

import ProfileFieldWrapper from '../../common/ProfileFieldWrapper';

interface SboIndustrySelectComponentProps {
  onChange: (value: string) => void;
  errors?: ProfileFieldErrorType;
  value?: string;
  subTitle?: ReactNode;
  type?: ProfileFieldLocationType;
}

const SboIndustrySelectComponent: React.FC<SboIndustrySelectComponentProps> = ({
  onChange,
  errors,
  value,
  subTitle: subTitleToSetImplicitly,
  type,
}) => {
  const { t } = useTenantTranslation();
  const configurationForIndustryOnboardingQuestion =
    useIndustryFieldConfiguration();
  // const availableIndustriesList = useMemo(() => getAvailableIndustriesList(t), [t]);
  const fieldId = useGoToFirstErrorId(['sboData.industry']);

  const availableValues = useMemo(() => {
    if (!configurationForIndustryOnboardingQuestion) {
      return [];
    }

    const translatedAvailableValues =
      configurationForIndustryOnboardingQuestion?.options?.map((elem) => ({
        name: t(elem?.name),
        value: elem?.value,
      }));

    return translatedAvailableValues || [];
  }, [t, configurationForIndustryOnboardingQuestion]);

  const subTitle = useMemo(() => {
    if (subTitleToSetImplicitly) {
      return subTitleToSetImplicitly;
    }

    if (type === 'onboarding') {
      return t('onboarding.business_info.industry_text');
    }

    return undefined;
  }, [subTitleToSetImplicitly, type, t]);

  return (
    <ProfileFieldWrapper
      title={t('onboarding.business_info.industry_title')}
      subTitle={subTitle}
      addAsteriskToTitle
      id={fieldId}
    >
      <DropDownList
        placeholder={`${t('onboarding.business_info.industry_placeholder')}`}
        value={value || ''}
        onChange={onChange}
        availableValuesList={availableValues}
        errors={errors}
        isArrow
      />
    </ProfileFieldWrapper>
  );
};

export default memo(SboIndustrySelectComponent);
