export interface CalendlyInterface {
  zoomUrl: string;
  phoneNumber: string;
  method: string;
  duration: string;
  startTime: number;
  endTime: number;
  topics: string;
  profileSharing: string;
}

export type ProfileCalendlyLinks = {
  link15: string;
  link30: string;
  link60: string;
};

export enum CalendlyIntegrationPolicyEnum {
  ORG_TOKEN = 'ORG_TOKEN', // calendly token is set by advisory org admin, calendly links are set by advisors
  NEXTSTREET_TOKEN = 'NEXTSTREET_TOKEN', // Next Street calendly token is used, calendly links are set by Next Street
}
