import { useEffect } from 'react';

import { fetchGetOnboardingQuestionConfiguration } from 'store/actions/onboarding';
import { useAppDispatch, useAppSelector } from 'store/configureStore';
import { onboardingQuestionConfigurationSelector } from 'store/selectors/onboarding';

const useOnboardingFieldConfiguration = (componentName: string) => {
  const dispatch = useAppDispatch();

  const industryFieldConfiguration = useAppSelector((state) =>
    onboardingQuestionConfigurationSelector(state, componentName),
  );

  useEffect(() => {
    if (!industryFieldConfiguration) {
      dispatch(fetchGetOnboardingQuestionConfiguration(componentName));
    }
  }, []);

  return industryFieldConfiguration;
};

export default useOnboardingFieldConfiguration;
