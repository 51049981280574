import { SboType } from './sbo.interface';

export interface SessionsCountType {
  havingUpcomingSessionsCount: number;
  allCount: number;
}

export interface GroupSessionType {
  sboInfoId: number;
  photo: string;
  sboType: SboType;
  businessName: string;
  firstName: string;
  lastName: string;
  count: string;
  upcoming: string;
  past: string;
}

export enum SessionLocationEnum {
  ZOOM = 'zoom',
  OUTBOUND_CALL = 'outbound_call',
  GOOGLE = 'google_conference',
  TEAMS = 'microsoft_teams_conference',
  GOTOMEETING = 'gotomeeting',
  WEBEX = 'webex_conference',
}

export interface SessionDetailsType {
  locationType: SessionLocationEnum;
  zoomUrl: string;
  phoneNumber: string;
  method: string;
  duration: string;
  startTime: number;
  endTime: number;
  advisorId: number;
  advisorName: string;
  advisorFirstName: string;
  advisorLastName: string;
  advisorPhoto: string;
  advisorVideo: string;
  advisorBio: string;
  advisoryOrgName: string;
  state: string[];
  profileSharing: string;
}

export interface SessionInfoType extends SessionDetailsType {
  sessionId: number;
  sessionName: string;
  sboInfoId: number;
  sboFirstName: string;
  sboLastName: string;
  sboName: string;
  sboPhoto: string;
  sboType: SboType;
  sboBusinessName: string;
  inviteeId: string;
  isRated: boolean;
}
