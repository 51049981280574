import React, { memo, useMemo } from 'react';
import { MdOutlineAccountBalance } from 'react-icons/md';

import { LoanItemType } from '_types/funding.interface';

import FundingCardTag from 'app/components/FundingCardTag';
import WordDefinitionTooltip from 'app/components/WordDefinitionTooltip';
import { TooltipPredefinedDescriptionKeys } from 'app/components/WordDefinitionTooltip/types';

import { useAppSelector } from 'store/configureStore';

import { showFundingExpandedViewModalSelector } from 'store/selectors/modal';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import { formatAs, processFundingValues } from 'utils/funding';
import useTenantColors from 'utils/hooks/useTenantColors';
import { useCurrentPlatformLanguage } from 'utils/translations';

import './style.scss';

const LoanCardExpandedView: React.FC = () => {
  const { t } = useTenantTranslation();
  const { colorVariables } = useTenantColors();
  const currentLanguage = useCurrentPlatformLanguage();

  const funding = useAppSelector(showFundingExpandedViewModalSelector);
  const fundingType = funding?.fundingType;
  const fundingValue = funding?.fundingValue;
  let loanValue!: LoanItemType;
  if (fundingType === 'Loan' && fundingValue) {
    loanValue = fundingValue as LoanItemType;
  }

  const loanStyle = {
    backgroundColor: loanValue.mainColor,
    color: loanValue.textColor,
  };

  const [creditScore, creditScoreCopy] = useMemo(() => {
    let creditScoreTemp: string | number;
    let creditScoreCopyTemp: string;

    if (loanValue.minCreditScore !== null && loanValue.minCreditScore !== 0) {
      creditScoreTemp = loanValue.minCreditScore;
      creditScoreCopyTemp = t('funding.minimum_credit_score');
    } else if (loanValue.maxCreditScore) {
      creditScoreTemp = loanValue.maxCreditScore;
      creditScoreCopyTemp = t('funding.maximum_credit_score');
    } else {
      creditScoreTemp = t('misc.none');
      creditScoreCopyTemp = t('funding.minimum_credit_score');
    }

    return [creditScoreTemp, creditScoreCopyTemp];
  }, [t]);

  const estimatedTimeToFund = processFundingValues(
    loanValue?.minTimeFunding,
    loanValue?.maxTimeFunding,
    'none',
    t,
    '',
  );

  return (
    <div className="loan_card_modal_container">
      <div className="tags">
        {/* <p>{t('funding.ideal_for')}</p> */}

        <FundingCardTag
          communityServed={loanValue.communityServed || ['loan_for_all']}
          productLocation={loanValue.productLocation}
          fundingType="loan"
        />
      </div>

      <div className="card">
        <div className="card_header" style={loanStyle}>
          <div className="card_header_icon">
            <MdOutlineAccountBalance
              size={24}
              color={colorVariables?.icons.icon_secondary_tetriary_button}
            />
          </div>

          <div className="card_header_text">
            {loanValue.loanName[currentLanguage]}
          </div>
        </div>

        <div className="card_body">
          <div className="card_item">
            <p>{loanValue.productType[currentLanguage]}</p>
          </div>

          <div className="card_item">
            <div className="card_item__title_with_tooltip">
              <p>{t('funding.typical_apr')}</p>

              <WordDefinitionTooltip
                predefinedDescriptionKey={TooltipPredefinedDescriptionKeys.APR}
              />
            </div>

            <p>
              {processFundingValues(
                loanValue.minAPR,
                loanValue.maxAPR,
                'percent',
                t,
              )}
            </p>
          </div>

          <div className="card_item">
            <p>{t('funding.typical_loan_size')}</p>
            <p>
              {processFundingValues(
                loanValue.minSize,
                loanValue.maxSize,
                'money',
                t,
              )}
            </p>
          </div>

          <div className="card_item">
            <p>{creditScoreCopy}</p>
            <p>{creditScore}</p>
          </div>

          <div className="card_item">
            <p>{t('funding.typical_term')}</p>
            <p>
              {processFundingValues(
                loanValue.minTerm,
                loanValue.maxTerm,
                'term',
                t,
              )}
            </p>
          </div>

          <div className="card_item">
            <p>{t('funding.minimum_annual_revenue')}</p>
            <p>
              {loanValue?.minRevenue && loanValue?.minRevenue !== 0
                ? formatAs(loanValue?.minRevenue, 'money')
                : t('misc.none')}
            </p>
          </div>

          <div className="card_item">
            <p>{t('funding.minimum_years_in_business')}</p>
            <p>
              {loanValue.minYearsInBusiness &&
              loanValue.minYearsInBusiness !== 0
                ? t('funding.n_years', { count: loanValue.minYearsInBusiness })
                : t('misc.none')}
            </p>
          </div>

          <div className="card_item">
            <p>{t('funding.loan_expanded_view.estimated_time_to_fund')}</p>
            <p>
              {estimatedTimeToFund
                ? t('funding.n_weeks', { count: estimatedTimeToFund })
                : t('funding.varies')}
            </p>
          </div>

          <div className="card_item">
            <div className="card_item__title_with_tooltip">
              <p>{t('funding.deferment_period')}</p>

              <WordDefinitionTooltip
                takeMaxWidth
                predefinedDescriptionKey={
                  TooltipPredefinedDescriptionKeys.DEFERMENT_PERIOD
                }
              />
            </div>

            <p>
              {loanValue.deferment[currentLanguage]
                ? loanValue.deferment[currentLanguage]
                : t('misc.none')}
            </p>
          </div>

          <div className="card_item">
            <p>{t('funding.target_audience')}</p>
            <p>{loanValue.targetAudience[currentLanguage] || t('misc.none')}</p>
          </div>

          <div className="card_item">
            <div className="card_item__title_with_tooltip">
              <p>{t('funding.basic_requirements')}</p>

              <WordDefinitionTooltip
                takeMaxWidth
                className="tooltip_basic_requirements"
                predefinedDescriptionKey={
                  TooltipPredefinedDescriptionKeys.BASIC_REQUIREMENTS
                }
              />
            </div>

            <ul>
              <li>
                <span>{t('funding.collateral')}:</span>{' '}
                {loanValue.collateral[currentLanguage] || t('misc.none')}
              </li>
              <li>
                <span>{t('funding.guarantor')}:</span>{' '}
                {loanValue.guarantor[currentLanguage] || t('misc.none')}
              </li>
              <li>
                <span>{t('funding.capital_injection')}:</span>{' '}
                {loanValue.capInjection[currentLanguage] || t('misc.none')}
              </li>
            </ul>
          </div>

          <div className="card_item">
            <p>{t('funding.product_differentiator')}</p>
            {loanValue?.keyDifferentiator[currentLanguage] ? (
              <ul>
                {Array.isArray(loanValue?.keyDifferentiator[currentLanguage]) &&
                  !!loanValue?.keyDifferentiator[currentLanguage] &&
                  loanValue?.keyDifferentiator[currentLanguage]!.map(
                    (item, index) => <li key={index}>{item}</li>,
                  )}
              </ul>
            ) : (
              <span>{t('misc.none')}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(LoanCardExpandedView);
