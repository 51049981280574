import { TranslateValueType } from '_types';
import { OnboardingQuestionConfiguration } from '_types/onboarding.interface';
import { TFunction } from 'i18next';

export const checkOtherSelectButton = (
  list: string[],
  elem: string,
  onboardingEntry: string | undefined,
) => {
  if (onboardingEntry) {
    if (list.includes(onboardingEntry)) {
      return onboardingEntry === elem;
    }
    return elem === 'Other, please specify...';
  }
  return false;
};

export const getSpecifyOtherValueFromList = (
  value: string[],
  availableValuesList: TranslateValueType[],
) => {
  let result = '';

  value.forEach((item) => {
    if (availableValuesList.findIndex((x) => x.value === item) === -1) {
      result = item;
    }
  });

  return result;
};

export const removeSpecifyOtherValueFromList = (
  value: string[],
  availableValuesList: TranslateValueType[],
) => {
  const specifyOther = getSpecifyOtherValueFromList(value, availableValuesList);

  return value.filter((x) => x !== specifyOther);
};

export const getAvailableValuesListFromFieldConfiguration = (
  fieldConfiguration: OnboardingQuestionConfiguration | null,
  t: TFunction,
): TranslateValueType[] => {
  if (!fieldConfiguration) {
    return [];
  }

  const translatedAvailableValues = fieldConfiguration?.options?.map(
    (elem) => ({
      name: t(elem?.name),
      value: elem?.value,
    }),
  );

  return translatedAvailableValues || [];
};
