import React, { memo, useMemo } from 'react';

import { ProfileFieldErrorType } from '_types/profile.interface';

import ProfileFieldWrapper from 'app/components/ProfileFields/common/ProfileFieldWrapper';
import ListWithSelectables from 'app/components/ListWithSelectables';

import useGoToFirstErrorId from 'utils/contexts/ProfileFieldIdsContext/useGoToFirstErrorId';
import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import useOnboardingFieldConfiguration from 'utils/hooks/profile-field-configuration/useOnboardingFieldConfiguration';
import { getAvailableValuesListFromFieldConfiguration } from 'utils/onboarding';

export enum SubIndustryTypeEnum {
  MANUFACTURING = 'manufacturing',
}

interface SboSubIndustrySelectComponentProps {
  value?: string | null;
  onChange: (value: string | null) => void;
  errors?: ProfileFieldErrorType;
  subIndustryType: SubIndustryTypeEnum;
}

const SboSubIndustrySelectComponent: React.FC<
  SboSubIndustrySelectComponentProps
> = ({ value, onChange, errors, subIndustryType }) => {
  const { t } = useTenantTranslation();
  const fieldConfiguration = useOnboardingFieldConfiguration(
    `subIndustry_${subIndustryType}`,
  );
  const fieldId = useGoToFirstErrorId(['sboData.subIndustry']);

  const subIndustryAvailableValues = useMemo(
    () => getAvailableValuesListFromFieldConfiguration(fieldConfiguration, t),
    [t, fieldConfiguration],
  );

  return (
    <ProfileFieldWrapper
      title={t(`onboarding.sub_industry.${subIndustryType}.title`)}
      addAsteriskToTitle
      id={fieldId}
    >
      <ListWithSelectables
        type="select"
        availableValuesList={subIndustryAvailableValues}
        value={value || ''}
        onChange={onChange}
        errors={errors}
      />
    </ProfileFieldWrapper>
  );
};

export default memo(SboSubIndustrySelectComponent);
