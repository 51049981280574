/* eslint-disable react/no-danger, jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label */
import React, { memo, useCallback } from 'react';
import { Trans } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { phoneConstant } from '_constants/otherConstants';

import { useAppDispatch, useAppSelector } from 'store/configureStore';

import { actionHandleShowExternalUrlConsentModal } from 'store/reducers/modal';
import { showExternalUrlConsentModalSelector } from 'store/selectors/modal';
import { tenantSettingsSelector } from 'store/selectors/tenant-settings';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';

import { FundingReferralType } from '_types/funding.interface';
import BaseModal from '../BaseModal';

import './style.scss';

const classNames = {
  modalFooter: 'info_modal_footer',
  modalContent: 'info_modal_content',
  modalTitle: 'info_modal_title',
  closeIcon: 'info_modal__closeIcon',
  acceptButton: 'info_modal__acceptButton',
  declineButton: 'info_modal__declineButton',
};

const ExternalUrlConsentModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const modalState = useAppSelector(showExternalUrlConsentModalSelector);
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneConstant}px)` });
  const tenantSettings = useAppSelector(tenantSettingsSelector);

  const closeModal = useCallback(() => {
    dispatch(actionHandleShowExternalUrlConsentModal(null));
  }, [dispatch]);

  const openExternalResource = useCallback(() => {
    document.getElementById('external-link-href')!.click();
    closeModal();
  }, [closeModal]);

  const { t } = useTenantTranslation();

  return (
    <BaseModal
      closeOnBackgroundClick
      withAcceptButton
      acceptButtonText={
        modalState?.acceptButtonText ||
        modalState?.fundingReferralType === FundingReferralType.WEBSITE
          ? t('misc.continue')
          : t('misc.yes_continue')
      }
      acceptButtonSize={isPhone ? 'small' : 'large'}
      onAcceptButtonClick={openExternalResource}
      withDeclineButton
      declineButtonText={t('misc.cancel')}
      declineButtonSize={isPhone ? 'small' : 'large'}
      declineButtonLook="filled-gray"
      onDeclineButtonClick={closeModal}
      onModalClose={closeModal}
      modalTitle={
        modalState?.title ||
        modalState?.fundingReferralType === FundingReferralType.WEBSITE
          ? t('misc.visit_website')
          : t('misc.external_resource_confirmation_title')
      }
      classNames={classNames}
      isCloseIconVisible
    >
      <p>
        <Trans
          ns={tenantSettings?.tenantTranslationPrefix}
          components={[
            <p key="0" />,
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              key="1"
              href="/advisors/list"
              aria-label="external resource link"
            />,
          ]}
        >
          {modalState?.fundingReferralType === FundingReferralType.WEBSITE
            ? t('misc.external_resource_confirmation_text2')
            : t('misc.external_resource_confirmation_text1')}
        </Trans>
      </p>

      <a
        id="external-link-href"
        href={modalState?.externalUrl || ''}
        target={modalState?.target || ''}
        rel={modalState?.rel || ''}
      />
    </BaseModal>
  );
};

export default memo(ExternalUrlConsentModal);
