import React, { memo } from 'react';

import { useAppSelector } from 'store/configureStore';

import {
  showAccountDeletionModalSelector,
  showCalculatorModalSelector,
  showCalendlyLinksModalSelector,
  showExternalUrlConsentModalSelector,
  showFundingExpandedViewModalSelector,
  showFundingModalSelector,
  showGeneralModalSelector,
  showInfoModalSelector,
  showLoginModalSelector,
  showMenuModalSelector,
  showProfileMissingDataSuccessModalSelector,
  showRateSessionModalSelector,
  showResetPasswordModalSelector,
} from 'store/selectors/modal';

import CookieConsentModal from './CookieConsentModal';
import GeneralModal from './GeneralModal';
import InfoModalNew from './InfoModalNew';
import LoginModal from './LoginModal';
import NonDigitalFundingInfoModal from './NonDigitalFundingInfoModal';
import RateModal from './RateModal';
import ResetPasswordModal from './ResetPasswordModal';
import ProfileMissingDataModalWrapper from './ProfileMissingDataModal/ProfileMissingDataModalWrapper';
import ProfileMissingDataSuccessModal from './ProfileMissingDataModal/ProfileMissingDataSuccessModal';
import ExternalUrlConsentModal from './ExternalUrlConsentModal';
import FundingCardExpandedViewModal from './FundingCardExpandedViewModal';
import MenuModal from './MenuModal';
import CalculatorModal from './CalculatorModal';
import AccountDeletionModal from './AccountDeleteModal/AccountDeletionModal';
import CalendlyLinksModal from './CalendlyLinksModal';

const ModalWindowsContainer: React.FC = () => {
  const showResetPasswordModal = useAppSelector(showResetPasswordModalSelector);
  const showLoginModal = useAppSelector(showLoginModalSelector);
  const showRateSessionModal = useAppSelector(showRateSessionModalSelector);
  const showGeneralModal = useAppSelector(showGeneralModalSelector);
  const showInfoModal = useAppSelector(showInfoModalSelector);
  const showNonDigitalFundingInfoModal = useAppSelector(
    showFundingModalSelector,
  );
  const showProfileMissingDataSuccessModal = useAppSelector(
    showProfileMissingDataSuccessModalSelector,
  );
  const showExternalUrlConsentModal = useAppSelector(
    showExternalUrlConsentModalSelector,
  );
  const showFundingCardExpandedViewModal = useAppSelector(
    showFundingExpandedViewModalSelector,
  );
  const showMenuModal = useAppSelector(showMenuModalSelector);
  const showCalculatorModal = useAppSelector(showCalculatorModalSelector);
  const showAccountDeletionModal = useAppSelector(
    showAccountDeletionModalSelector,
  );
  const showCalendlyLinksModal = useAppSelector(showCalendlyLinksModalSelector);

  return (
    <>
      {showResetPasswordModal && <ResetPasswordModal />}
      {showLoginModal && <LoginModal />}
      {showRateSessionModal && <RateModal />}
      {showInfoModal && <InfoModalNew />}
      {showGeneralModal && <GeneralModal />}
      {showNonDigitalFundingInfoModal && <NonDigitalFundingInfoModal />}
      {showProfileMissingDataSuccessModal && <ProfileMissingDataSuccessModal />}
      {showExternalUrlConsentModal && <ExternalUrlConsentModal />}
      {showFundingCardExpandedViewModal && <FundingCardExpandedViewModal />}
      {showMenuModal && <MenuModal />}
      {showCalculatorModal && <CalculatorModal />}
      {showAccountDeletionModal && <AccountDeletionModal />}
      {showCalendlyLinksModal && <CalendlyLinksModal />}
      <ProfileMissingDataModalWrapper />
      <CookieConsentModal />
    </>
  );
};

export default memo(ModalWindowsContainer);
