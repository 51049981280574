import { TenantTypeEnum } from '_types/tenant-settings';
import { SubIndustryTypeEnum } from './SboSubIndustrySelectComponent';

const industryManufacturingValue = 'Manufacturing';
const showSubIndustryOnTenants = [TenantTypeEnum.MICHIGAN];

export const getSubIndustryTypeToShow = (
  tenantType: TenantTypeEnum,
  industryValue?: string | null,
): SubIndustryTypeEnum | null => {
  if (!showSubIndustryOnTenants.includes(tenantType)) {
    return null;
  }

  if (industryValue === industryManufacturingValue) {
    return SubIndustryTypeEnum.MANUFACTURING;
  }

  return null;
};
