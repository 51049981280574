import React, { memo, useEffect, useState } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Trans } from 'react-i18next';

import Loader from 'app/components/Loader';

import { useAppDispatch, useAppSelector } from 'store/configureStore';

import { tenantSettingsSelector } from 'store/selectors/tenant-settings';
import { actionHandleShowInfoModal } from 'store/reducers/modal';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';

import { cookieNameForRedirectsFromVerizon } from './constants';
import { doStartupCheckRedirectedFromVerizon } from './utils';

const TenantSpecificStartupChecksWrapper: React.FC = memo(() => {
  const tenantSettings = useAppSelector(tenantSettingsSelector)!;

  const { t } = useTenantTranslation();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [cookies, setCookie] = useCookies([cookieNameForRedirectsFromVerizon]);

  const [isChecking, setIsChecking] = useState<boolean>(true);

  const registerBeforeProceedingLink =
    process.env.NODE_ENV !== 'production'
      ? 'https://dashboard-pilot-digitalready.verizonwireless.com/course-details/capital-offering'
      : 'https://dashboard-digitalready.verizonwireless.com/course-details/capital-offering';

  useEffect(() => {
    const isVerizonCheckEnabled =
      tenantSettings.tenantModules?.isRedirectedFromVerizonCheckEnabled;

    if (isVerizonCheckEnabled) {
      const isRedirectedFromVerizonCheckSuccessful =
        doStartupCheckRedirectedFromVerizon(
          cookies,
          searchParams,
          setCookie,
          setSearchParams,
        );

      if (!isRedirectedFromVerizonCheckSuccessful) {
        dispatch(
          actionHandleShowInfoModal({
            modalTitle: t('misc.redirect_from_verizon_check_failed.title'),
            content: (
              <Trans
                components={[
                  // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label
                  <a
                    key="0"
                    href={registerBeforeProceedingLink}
                    target="_blank"
                    rel="noreferrer"
                  />,
                ]}
              >
                {t('misc.redirect_from_verizon_check_failed.text')}
              </Trans>
            ),
            modalCloseDisabled: true,
          }),
        );

        return;
      }
    }

    setIsChecking(false);
  }, [t]);

  if (isChecking) {
    return <Loader />;
  }

  return <Outlet />;
});

export default TenantSpecificStartupChecksWrapper;
