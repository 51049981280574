import {
  SessionInfoType,
  SessionLocationEnum,
} from '_types/sessions.interface';

export const getSessionMethodTranslationKey = (session: SessionInfoType) => {
  if (session.locationType === SessionLocationEnum.ZOOM) {
    return 'misc.video_call_zoom';
  }
  if (session.locationType === SessionLocationEnum.GOOGLE) {
    return 'misc.video_call_gmeet';
  }
  if (session.locationType === SessionLocationEnum.TEAMS) {
    return 'misc.video_call_teams';
  }
  if (session.locationType === SessionLocationEnum.GOTOMEETING) {
    return 'misc.video_call_gotomeet';
  }
  if (session.locationType === SessionLocationEnum.WEBEX) {
    return 'misc.video_call_webex';
  }
  if (session.locationType === SessionLocationEnum.OUTBOUND_CALL) {
    return 'misc.outbound_phone_call';
  }

  return 'misc.unknown';
};
