import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'store/configureStore';
import { addLoadedTranslationNamespace } from 'store/reducers/translations';

import { tenantSettingsSelector } from 'store/selectors/tenant-settings';
import { translationNamespacesLoadedSelector } from 'store/selectors/translations';

type UseTranslationReturnType = ReturnType<typeof useTranslation>;
type UseTenantTranslationReturnType = UseTranslationReturnType & {
  loadI18NNamespace: (namespace: string) => Promise<void>;
};

const useTenantTranslation = () => {
  const dispatch = useAppDispatch();
  const namespacesLoaded = useAppSelector(translationNamespacesLoadedSelector);
  const tenantSettings = useAppSelector(tenantSettingsSelector);
  const tenantTranslationPrefix = tenantSettings?.tenantTranslationPrefix;

  const defaultUseTranslationReturnValue = useTranslation();
  const { t: defaultTFunc, i18n } = defaultUseTranslationReturnValue;

  const t = useCallback(
    (key, options) => {
      if (
        !tenantTranslationPrefix ||
        !namespacesLoaded.includes(tenantTranslationPrefix)
      ) {
        return defaultTFunc(key, options);
      }
      if (i18n.exists(key, { ns: tenantTranslationPrefix })) {
        return defaultTFunc(key, { ns: tenantTranslationPrefix, ...options });
      }
      return defaultTFunc(key, options);
    },
    [tenantTranslationPrefix, defaultTFunc, i18n, namespacesLoaded],
  );

  const loadI18NNamespace = useCallback(
    (namespace: string) => {
      i18n.loadNamespaces(namespace, (err, t) => {
        dispatch(addLoadedTranslationNamespace(namespace));

        if (err) {
          // eslint-disable-next-line no-console
          console.error('Error loading namespace', err);
        }
      });
    },
    [i18n, dispatch],
  );

  return useMemo(
    () =>
      ({
        ...defaultUseTranslationReturnValue,
        t,
        loadI18NNamespace,
      }) as UseTenantTranslationReturnType,
    [t, defaultUseTranslationReturnValue],
  );
};

export default useTenantTranslation;
