import React, {
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import clsx from 'clsx';

import { UserRolesEnum } from '_types/user.interface';

import { useAppSelector } from 'store/configureStore';

import { profileRoleSelector } from 'store/selectors/profile';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';

import CheckedIcon from '../Icons/CheckedIcon';
import Input from '../InputComponents/Input';
import SelectButtonHint from '../SelectButtonHint';

import './style.scss';

interface SelectButtonProps {
  rightDownIcon?: JSX.Element;
  leftDownIcon?: JSX.Element;
  value: string;
  onChange?: Function;
  onClick: Function;
  disabled?: boolean;
  selected: boolean;
  type: 'checkbox' | 'select';
  inputSelect?: boolean;
  inputSelectText?: string;
  starIcon?: JSX.Element;
  hint?: ReactNode;
  hintPosition?: 'right' | 'left';
}
const SelectButton: React.FC<SelectButtonProps> = ({
  rightDownIcon,
  value,
  onChange,
  onClick,
  disabled,
  selected,
  type,
  inputSelect,
  leftDownIcon,
  inputSelectText,
  starIcon,
  hint,
  hintPosition = 'right',
}) => {
  const { t } = useTenantTranslation();

  const userRole = useAppSelector(profileRoleSelector);
  const [entryValue, setEntryValue] = useState(value);
  const [inputFocus, setInputFocus] = useState<boolean | null>(null);

  const changeValue = useCallback(
    (newValue: string, event: React.ChangeEvent<HTMLElement>) => {
      event?.preventDefault();
      event?.stopPropagation();

      if (onChange) {
        onChange(newValue);
      }
    },
    [onChange],
  );

  useEffect(() => {
    setEntryValue(value);
  }, [value]);

  useEffect(() => {
    if (type === 'checkbox' && onChange && inputSelect && !selected && value) {
      onChange('');
      setInputFocus(!selected);
    }
  }, [selected]);

  const onButtonClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      const eventTarget = event.target as HTMLTextAreaElement;
      const isClickOnButton = eventTarget
        .getAttribute('class')
        ?.includes('select_button');

      if (!isClickOnButton) {
        return;
      }

      onClick(inputSelect && entryValue);
      setInputFocus(false);
    },
    [onClick, inputSelect, entryValue, setInputFocus],
  );

  return (
    <div
      className={clsx('select_button', {
        active: selected,
        entry: inputSelect,
      })}
      onClick={onButtonClick}
    >
      <div className="select_button__block">
        {type === 'select' ? (
          <div
            className={clsx('select_button__circle', { active: selected })}
          />
        ) : type === 'checkbox' ? (
          <div className={clsx('select_button__box', { active: selected })}>
            {selected && <CheckedIcon className="select_button__box_icon" />}
          </div>
        ) : null}

        {leftDownIcon && (
          <div className={clsx('select_button__icon', 'left')}>
            {leftDownIcon}
          </div>
        )}

        {inputSelect ? (
          <Input
            type="text"
            placeholder={t('misc.other_specify')}
            value={entryValue}
            onChange={changeValue}
            handleFocus={{ inputFocus, setInputFocus }}
          />
        ) : (
          <div className="select_button__text">{value}</div>
        )}

        {userRole === UserRolesEnum.SBO && starIcon && <div> {starIcon} </div>}
      </div>

      {hint && <SelectButtonHint hint={hint} position={hintPosition} />}

      <div className="select_button__icon">{rightDownIcon}</div>
    </div>
  );
};

export default memo(SelectButton);
