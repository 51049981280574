import React, { memo, useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import { orderBy } from 'lodash';
import { MdOutlineLocationOn } from 'react-icons/md';

import {
  GrantCommunitiesServedEnum,
  LoanCommunitiesServedEnum,
  ProductLocationType,
} from '_types/funding.interface';

import { useAppSelector } from 'store/configureStore';

import { tenantSettingsTenantStylingSelector } from 'store/selectors/tenant-settings';

import useOnClickOutside from 'utils/hooks/useOnClickOutside';
import { getFundingProductLocationDisplayValue } from 'utils/funding';
import useTenantColors from 'utils/hooks/useTenantColors';
import useTenantTranslation from 'utils/hooks/useTenantTranslation';

import './style.scss';

interface FundingCardTagProps {
  communityServed: (LoanCommunitiesServedEnum | GrantCommunitiesServedEnum)[];
  productLocation?: ProductLocationType;
  fundingType: 'loan' | 'grant';
  classNames?: {
    tags_container?: string;
    tag_item?: string;
  };
}
const FundingCardTag: React.FC<FundingCardTagProps> = (props) => {
  const { t } = useTenantTranslation();
  const {
    communityServed,
    productLocation = ProductLocationType.NATIONAL,
    fundingType,
    classNames,
  } = props;
  const [isFloatingIcon, setFloatingIcon] = useState<boolean>(false);
  const showTags: boolean = communityServed.length > 0 || !!productLocation;
  const tenantStyling = useAppSelector(tenantSettingsTenantStylingSelector);
  const { colorVariables } = useTenantColors();
  const toggleFloatingIcon = useCallback(
    () => setFloatingIcon((isOpen) => !isOpen),
    [setFloatingIcon],
  );
  const onOutsideClick = useCallback(() => {
    if (isFloatingIcon) {
      setFloatingIcon(false);
    }
  }, [isFloatingIcon, setFloatingIcon]);
  const { ref } = useOnClickOutside(true, onOutsideClick);

  const communityServedTranslated = useMemo(
    () =>
      communityServed.map((community) => {
        if (fundingType === 'loan') {
          return community === LoanCommunitiesServedEnum.ALL
            ? t(`funding.communities_served_available.loan_for_all`)
            : t(`funding.communities_served_available.${community}`);
        }
        return community === GrantCommunitiesServedEnum.ALL
          ? t(`funding.communities_served_available.grant_for_all`)
          : t(`funding.communities_served_available.${community}`);
      }),
    [communityServed, t],
  );

  const communityServedSorted = useMemo(
    () =>
      orderBy(
        communityServedTranslated,
        [(s) => s.length, (s) => s],
        ['asc', 'asc'],
      ),
    [communityServedTranslated],
  );

  return showTags ? (
    <div className={clsx('tags_container', classNames?.tags_container)}>
      {communityServedSorted
        .slice(0, communityServedSorted.length > 2 ? 1 : 2)
        .map((community, index) => (
          <div className={clsx('tag_item', classNames?.tag_item)} key={index}>
            {/* <DemographicsIcon /> */}
            <span>{community}</span>
          </div>
        ))}

      {!!productLocation && (
        <div className={clsx('tag_item', classNames?.tag_item)}>
          <MdOutlineLocationOn
            size={24}
            color={colorVariables?.icons.icon_secondary_tetriary_button}
          />
          <span>
            {getFundingProductLocationDisplayValue(productLocation, t)}
          </span>
        </div>
      )}

      {communityServedSorted.length > 2 && (
        <div
          className={clsx('tag_item', classNames?.tag_item)}
          style={{
            ...(isFloatingIcon ? { border: '1px solid var(--main-blue)' } : {}),
          }}
          onClick={toggleFloatingIcon}
          ref={ref}
          id="float"
        >
          <span>
            +{communityServedSorted.length - (productLocation ? 1 : 2)}
          </span>

          {isFloatingIcon && (
            <div className="floating_tags_container">
              {communityServedSorted
                .slice(productLocation ? 1 : 2)
                .map((community, index) => (
                  <div key={index} className="floating_tag_item">
                    {/* <DemographicsIcon /> */}
                    <span>{community}</span>
                  </div>
                ))}
            </div>
          )}
        </div>
      )}
    </div>
  ) : null;
};

export default memo(FundingCardTag);
