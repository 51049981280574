import React, { memo, useLayoutEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { CalendlyIntegrationPolicyEnum } from '_types/calendly.interface';

import { useAppDispatch, useAppSelector } from 'store/configureStore';
import {
  isProfileAdvisorOrAdvisoryOrgAdminSelector,
  isProfileInfoPresentSelector,
  profileAdvisorCalendlyLinksSelector,
  profileHasSkippedMFASetupSelector,
  profileIsAdvisorSelector,
  profileIsEmailConfirmedSelector,
  profileIsMFASetUpSelector,
  profileOrgCalendlyIntegrationPolicySelector,
} from 'store/selectors/profile';
import {
  isOnboardingCompletedSelector,
  isOnboardingProgressPresentSelector,
} from 'store/selectors/onboarding';
import { actionHandleShowCalendlyLinksModal } from 'store/reducers/modal';
import { hasCalendlyLinksModalBeenShownSelector } from 'store/selectors/modal';
import { tenantSettingsTenantModulesSelector } from 'store/selectors/tenant-settings';

import { auth } from 'utils/firebase/FirebaseRemoteConfigInitialization';

import { checkIfShouldRedirect } from './utils';

const mfaSetupPagePath = '/authenticated/mfa/setup';
const emailVerificationPagePath = '/authenticated/email-verification';
const onboardingValidationPagePath = '/onboarding';

const pagesToDisableEmailVerificationCheckOn: RegExp[] = [
  new RegExp(emailVerificationPagePath),
];
const pagesToDisableMFASetupCheckOn: RegExp[] = [
  ...pagesToDisableEmailVerificationCheckOn,
  new RegExp(mfaSetupPagePath),
];
const pagesToDisableOnboardingCheckOn: RegExp[] = [
  ...pagesToDisableMFASetupCheckOn,
  new RegExp(onboardingValidationPagePath),
];
const pagesToDisableCalendlyLinksCheckOn = pagesToDisableOnboardingCheckOn;

const doNotForceEmailVerification =
  process.env.REACT_APP_EMAIL_VERIFICATION_REQUIRED === 'false';
const doNotForceMFASetup = process.env.REACT_APP_MFA_SETUP_REQUIRED === 'false';

const AuthenticatedUserStartupChecksWrapper: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const tenantModules = useAppSelector(tenantSettingsTenantModulesSelector);
  const isProfileInfoPresent = useAppSelector(isProfileInfoPresentSelector);
  const hasSkippedMFASetup = useAppSelector(profileHasSkippedMFASetupSelector);
  const isProfileAdvisorOrAdvisoryOrgAdmin = useAppSelector(
    isProfileAdvisorOrAdvisoryOrgAdminSelector,
  );
  const isProfileMFASetUp = useAppSelector(profileIsMFASetUpSelector);
  const isProfileEmailConfirmed = useAppSelector(
    profileIsEmailConfirmedSelector,
  );

  const isOnboardingProgressPresent = useAppSelector(
    isOnboardingProgressPresentSelector,
  );
  const isOnboardingCompleted = useAppSelector(isOnboardingCompletedSelector);

  const isAdvisor = useAppSelector(profileIsAdvisorSelector);
  const calendlyLinks = useAppSelector(profileAdvisorCalendlyLinksSelector);
  const hasCalendlyLinksModalBeenShown = useAppSelector(
    hasCalendlyLinksModalBeenShownSelector,
  );
  const orgCalendlyIntegrationPolicy = useAppSelector(
    profileOrgCalendlyIntegrationPolicySelector,
  );

  const checksToRunOrdered = useMemo(
    // getIsOk code is executed only when there's profile data and when check not disabled
    () => [
      {
        getIsOk: () => doNotForceEmailVerification || isProfileEmailConfirmed,
        action: () => navigate(emailVerificationPagePath),
        pathRegExpsToDisableCheckOn: pagesToDisableEmailVerificationCheckOn,
      },
      {
        getIsOk: () =>
          doNotForceMFASetup ||
          (!isProfileAdvisorOrAdvisoryOrgAdmin && hasSkippedMFASetup) ||
          isProfileMFASetUp,
        action: () => navigate(mfaSetupPagePath),
        pathRegExpsToDisableCheckOn: pagesToDisableMFASetupCheckOn,
      },
      {
        getIsOk: () => isOnboardingCompleted!,
        action: () => navigate(onboardingValidationPagePath),
        pathRegExpsToDisableCheckOn: pagesToDisableOnboardingCheckOn,
      },
      {
        getIsOk: () =>
          !!hasCalendlyLinksModalBeenShown ||
          !isAdvisor ||
          orgCalendlyIntegrationPolicy !==
            CalendlyIntegrationPolicyEnum.NEXTSTREET_TOKEN ||
          !isEmpty(Object.values(calendlyLinks).filter(Boolean)),
        action: () => dispatch(actionHandleShowCalendlyLinksModal(true)),
        pathRegExpsToDisableCheckOn: pagesToDisableCalendlyLinksCheckOn,
      },
    ],
    [
      isProfileEmailConfirmed,
      isProfileAdvisorOrAdvisoryOrgAdmin,
      hasSkippedMFASetup,
      isProfileMFASetUp,
      isOnboardingCompleted,
      isAdvisor,
      calendlyLinks,
    ],
  );

  useLayoutEffect(() => {
    const isRequiredUserDataLoaded =
      auth.currentUser && isProfileInfoPresent && isOnboardingProgressPresent;

    if (!tenantModules?.users || !isRequiredUserDataLoaded) {
      return;
    }

    // Run checks
    const currentPathname = location.pathname;

    // eslint-disable-next-line no-restricted-syntax
    for (const checkObj of checksToRunOrdered) {
      const isCheckFailed = checkIfShouldRedirect(
        checkObj.getIsOk,
        currentPathname,
        checkObj.pathRegExpsToDisableCheckOn,
      );

      if (isCheckFailed) {
        checkObj.action();
        return;
      }
    }
  }, [
    location.pathname,
    checksToRunOrdered,
    isProfileInfoPresent,
    isOnboardingProgressPresent,
    tenantModules,
  ]);

  return <Outlet />;
};

export default memo(AuthenticatedUserStartupChecksWrapper);
